<template>
  <div class="container">
    <div class="row rooms justify-content-center mt-4">
      <div>
        <div>
          <ActionPanel
            :tabs="state.tableTabs"
            :show-export="state.filterParams.activeTab.value != 'files'"
            :show-search="state.filterParams.activeTab.value != 'files'"
            @on-search="searchByQuery"
            @on-export="getTableData(state.pagination.activePage, true)"
            @tab-clicked="setActiveTableTab"
          >
            <template #right-last-content>
              <IconButton
                data-test-id="room-table-manage-favorites"
                class="action-panel-favorites"
                icon="ri-star-line"
                text="Manage favorites"
                v-if="state.filterParams.activeTab.value != 'files'"
                @click="openFavoritesModal"
              >
              </IconButton> </template
          ></ActionPanel>
        </div>

        <div class="my-3">
          <div class="status-guides-container d-block">
            <PerPage
              v-if="
                state.filterParams.activeTab.value == 'files'
                  ? true
                  : roomsCollection
              "
              :pagination="roomsMeta"
              :showing-entries="true"
              @on-page-change="setPerPage"
            />
          </div>
        </div>
        <Loader :is-processing="state.isProcessing" />
        <div class="py-1">
          <DataTable
            data-test-id="locations-data-table"
            v-if="
              state.filterParams.activeTab.value == 'files'
                ? true
                : roomsCollection
            "
            class="px-3"
            :items="
              state.filterParams.activeTab.value == 'files'
                ? roomFiles
                : roomsCollection
            "
            :fields="activeTableFields"
            :sorter="{ external: true }"
            column-filter
            :no-items-view="{
              noResults: 'No items available',
              noItems: 'No items available'
            }"
            @update:sorter-value="sortByColumn"
          >
            <template #search_column>
              <div class="sort-icon-v3">
                <i
                  class="ri-search-line"
                  data-test-id="toggle-search-column"
                  @click="toggleSearchByColumn"
                ></i>
              </div>
            </template>

            <template #admin_favorite="{ item }">
              <td
                v-if="user.role.name == 'admin'"
                class="text-center"
                :class="{ 'is-favorite': item.admin_favorite }"
              >
                <div v-if="item.status">
                  <div v-if="fromFavorites.length < 10">
                    <i
                      data-test-id="add-favorites-table"
                      class="ri-star-fill"
                      @click="addAdminFav(item)"
                      :style="
                        (item.admin_favorite
                          ? 'color: #005DBA;'
                          : 'color: #AFBECC;') +
                        (user.role.name == 'admin' ? 'cursor:pointer' : '')
                      "
                    ></i>
                  </div>
                  <div v-else>
                    <i
                      data-test-id="add-favorites-table"
                      v-if="item.admin_favorite"
                      class="ri-star-fill"
                      @click="addAdminFav(item)"
                      :style="
                        (item.admin_favorite
                          ? 'color: #005DBA;'
                          : 'color: #AFBECC;') +
                        (user.role.name == 'admin' ? 'cursor:pointer' : '')
                      "
                    ></i>
                    <i
                      v-else
                      class="ri-star-fill"
                      :style="'color: #AFBECC;'"
                    ></i>
                  </div>
                </div>
              </td>
              <td v-else>
                <i class="ri-star-fill" :style="'color: #AFBECC;'"></i>
              </td>
            </template>

            <template #name="{ item }">
              <td>
                <div class="d-flex align-items-center">
                  <div
                    @click="editRoomIcon(item)"
                    class="d-flex align-items-center"
                  >
                    <i
                      v-if="item.icon"
                      :class="item.icon"
                      class="name-icon"
                    ></i>
                    <i v-else class="ri-add-line name-icon"></i>
                  </div>
                  <div data-test-id="room-table-name">
                    {{ item.name }}
                  </div>
                </div>
              </td>
            </template>
            <template #name-filter>
              <input
                v-show="state.filter"
                data-test-id="room-table-search-name"
                v-model="state.columnSearchKeys.name.value"
                class="form-control form-control-sm my-2"
                @input="searchByQuery($event.target.value, 'column')"
              />
            </template>

            <template #comment_type="{ item }">
              <td>
                <span data-test-id="room-table-comment">{{
                  item.comment_type
                }}</span>
              </td>
            </template>
            <template #comment_type-filter>
              <input
                v-show="state.filter"
                data-test-id="room-table-search-comment"
                v-model="state.columnSearchKeys.comment.value"
                class="form-control form-control-sm my-2"
                @input="searchByQuery($event.target.value, 'column')"
              />
            </template>

            <template #trip_type="{ item }">
              <td data-test-id="room-table-type">
                <i
                  v-if="item.trip_type == 'One Way'"
                  class="ri-arrow-right-line"
                ></i>
                <i
                  v-else-if="item.trip_type == 'Layover'"
                  class="ri-arrow-left-right-line"
                ></i>
                <i v-else class="ri-arrow-go-back-line"></i>
                {{ item.trip_type }}
              </td>
            </template>

            <template #trip_type-filter>
              <input
                v-show="state.filter"
                data-test-id="room-table-search-trip-type"
                v-model="state.columnSearchKeys.trip.value"
                class="form-control form-control-sm my-2"
                @input="searchByQuery($event.target.value, 'column')"
              />
            </template>

            <template #extended_pass_time="{ item }">
              <td data-test-id="room-table-time">
                {{ item.extended_pass_time }}
              </td>
            </template>
            <template #extended_pass_time-filter>
              <input
                v-show="state.filter"
                data-test-id="room-table-search-extended-pass-time"
                v-model="state.columnSearchKeys.extended_pass_time.value"
                class="form-control form-control-sm my-2"
                @input="searchByQuery($event.target.value, 'column')"
              />
            </template>

            <template #enable_appointment_passes="{ item }">
              <td>
                <div class="d-flex align-items-center">
                  <span>No</span>
                  <CustomSwitch
                    class="mx-3"
                    :model-value="Boolean(item.enable_appointment_passes)"
                    @update:model-value="allowAPTRequestToRoom(item)"
                  />
                  <span>Yes</span>
                </div>
              </td>
            </template>
            <template #enable_appointment_passes-filter>
              <span></span>
            </template>

            <template #status="{ item }">
              <td v-if="state.filterParams.activeTab.value != 'files'">
                <StatusPill
                  :status="
                    item.status === '1' || item.status === 1
                      ? 'STATUS_ACTIVE'
                      : 'STATUS_INACTIVE'
                  "
                  :status-icon="
                    item.status === '1' || item.status === 1
                      ? 'ri-check-line'
                      : 'ri-close-line'
                  "
                >
                </StatusPill>
              </td>
              <td v-if="state.filterParams.activeTab.value == 'files'">
                <span
                  class="px-3 text-uppercase rounded py-1 text-white fw-bold d-block text-center w-full"
                  :class="{
                    'bg-red-failed-status': item.status == 'failed',
                    'bg-green-success-status': item.status == 'success',
                    'bg-blue': item.status == 'inprogress',
                    'bg-yellow': item.status == 'queue'
                  }"
                  >{{ item.status }}</span
                >
              </td>
            </template>

            <template #status-filter><span></span></template>

            <template #action="{ item }">
              <td class="text-end">
                <Actions
                  data-test-id="pass-block-actions"
                  :actions="roomAction"
                  @click-action="handleTableAction($event, item)"
                >
                </Actions>
              </td>
            </template>

            <template #created_at="{ item }">
              <td>
                {{
                  item.created_at
                    ? $helpers.transformDate(
                        item.created_at,
                        "MM/DD/YYYY h:mm A"
                      )
                    : ""
                }}
              </td>
            </template>
          </DataTable>
        </div>
      </div>
      <Pagination
        v-if="
          state.pagination.pages &&
          state.pagination.pages > 1 &&
          !state.isLazyLoadingMode
        "
        class="cs-pagination mt-4"
        :dots="false"
        :double-arrows="true"
        :active-page="state.pagination.activePage"
        :pages="state.pagination.pages"
        align="center"
        size="lg"
        @update:active-page="setActivePage"
      >
      </Pagination>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed, inject, watch } from "vue"
import Loader from "@/v3components/shared/Loader/Loader"
import { useStore } from "vuex"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import FavoritesForm from "@/v3components/Forms/FavoritesForm.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import StatusPill from "@/v3components/shared/DataTable/StatusPill.vue"
import Actions from "@/v3components/shared/DataTable/Actions"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import NewRoomForm from "@/v3components/Forms/NewRoomForm.vue"
import helpers from "../../helpers/index"
import download from "@/helpers/downloadCSV"

export default {
  name: "RoomsDataTable",
  components: {
    ActionPanel,
    IconButton,
    Loader,
    PerPage,
    CustomSwitch,
    StatusPill,
    Actions,
    Pagination,
    DataTable
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      isProcessing: false,
      searchTimeOut: null,
      isLazyLoadingMode: false,
      filter: false,
      pagination: {
        activePage: 1,
        total: 0,
        to: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      tableTabs: [
        { label: "Rooms", value: "rooms" },
        { label: "Files", value: "files" }
      ],
      filterParams: {
        sort_query: "",
        search_query: "",
        activeTab: { label: "", value: "" }
      },
      columnSearchKeys: {
        globalQuery: "",
        name: {
          value: "",
          column: ["name"],
          sortBy: "name"
        },
        comment: {
          value: "",
          column: ["comment_type"],
          sortBy: "comment_type"
        },
        trip: {
          value: "",
          column: ["trip_type"],
          sortBy: "trip_type"
        },
        extended_pass_time: {
          value: "",
          column: ["extended_pass_time"],
          sortBy: "extended_pass_time"
        }
      }
    })

    const dataTableFields = [
      {
        key: "admin_favorite",
        label: "",
        filter: false,
        sorter: false,
        _style: "width: 50px;",
        tabs: ["rooms"]
      },
      {
        key: "name",
        label: "Location",
        tabs: ["rooms"]
      },
      {
        key: "comment_type",
        label: "Comment",
        tabs: ["rooms"]
      },
      {
        key: "trip_type",
        label: "Trip Type",
        tabs: ["rooms"]
      },
      {
        key: "extended_pass_time",
        label: "Extended Pass Time",
        tabs: ["rooms"]
      },
      {
        key: "enable_appointment_passes",
        label: "Allow student APT Requests",
        requireLocationModule: true,
        filter: false,
        tabs: ["rooms"]
      },
      {
        key: "status",
        label: "Status",
        filter: false,
        tabs: ["rooms"]
      },
      {
        key: "action",
        label: "Action",
        filter: false,
        sorter: false,
        _style: "width: 50px;",
        tabs: ["rooms"]
      },
      {
        key: "file_name",
        label: "File name",
        filter: false,
        sorter: false,
        _style: "width: 130px;",
        tabs: ["files"]
      },
      {
        key: "status",
        label: "Status",
        filter: false,
        sorter: false,
        _style: "width: 50px;",
        tabs: ["files"]
      },
      {
        key: "created_at",
        label: "Created On",
        filter: false,
        sorter: false,
        _style: "width: 150px;",
        tabs: ["files"]
      }
    ]

    const roomAction = [{ label: "Edit", icon: "ri-edit-line", action: "edit" }]

    const rooms = computed(() => {
      return store.getters["rooms/rooms"]
    })

    const roomFiles = computed(() => store.getters["rooms/roomFiles"])

    const roomsCollection = computed(() =>
      rooms.value?.data
        ? rooms.value.data.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id)
          )
        : []
    )
    const activeAppoinmentModuleOptions = computed(
      () => store.getters["schools/activeAppoinmentModuleOptions"]
    )
    const user = computed(() => store.getters["authentication/user"])
    const fromFavorites = computed(
      () => store.getters["favorites/fromFavorites"]
    )

    const activeTableFields = computed(() => {
      return activeAppoinmentModuleOptions.value &&
        !activeAppoinmentModuleOptions.value.location
        ? dataTableFields
            .filter((field) => !field.requireLocationModule)
            .filter((field) =>
              field.tabs.includes(state.filterParams.activeTab.value)
            )
        : dataTableFields.filter((field) =>
            field.tabs.includes(state.filterParams.activeTab.value)
          )
    })

    onMounted(() => {
      initLazyLoading()
      getTableData()
      state.filterParams.activeTab = state.tableTabs[0]
      store.dispatch("favorites/getFavorites")
    })

    const setActiveTableTab = (tab) => {
      state.filterParams.activeTab = tab
      resetRoomFilesPagination()
      if (tab.value == "files") {
        getRoomFilesTableData()
      } else {
        getTableData()
      }
    }

    const getTableData = (page, CSVExport, isLazyLoadingMode = false) => {
      state.isProcessing = !isLazyLoadingMode
      state.pagination.activePage = page ? page : 1
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value,
        commitToTheStore: !isLazyLoadingMode
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (CSVExport) {
        generateExport(params)
      } else {
        getRooms(params, isLazyLoadingMode)
      }
    }

    const getRooms = (params, isLazyLoadingMode) => {
      store
        .dispatch("rooms/getRooms", params)
        .then((response) => {
          const data = response.data
          if (isLazyLoadingMode && data.data.length) {
            store.commit("rooms/PUSH_ROOMS", data.data)
          } else {
            store.commit("rooms/SET_ROOMS", data)
          }
          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isProcessing = false

          state.pagination.total = data.meta.total
          state.pagination.from = data.meta.from
          state.pagination.to = data.meta.to
          state.pagination.pages = Math.ceil(
            data.meta.total / data.meta.per_page
          )
          state.pagination.activePage = Number(state.pagination.activePage)
          state.pagination = JSON.parse(JSON.stringify(state.pagination))
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const getRoomFilesTableData = (page, isLazyLoadingMode = false) => {
      state.isProcessing = !isLazyLoadingMode
      state.pagination.activePage = page ? page : 1
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      store
        .dispatch("rooms/getRoomFiles", params)
        .then((response) => {
          const data = response.data
          if (isLazyLoadingMode && data.data.length) {
            store.commit("rooms/PUSH_ROOM_FILES", data.data)
          } else {
            store.commit("rooms/SET_ROOM_FILES", data.data)
          }
          state.isProcessing = false
          state.pagination.total = data.meta.total
          state.pagination.from = data.meta.from
          state.pagination.to = data.meta.to
          state.pagination.pages = Math.ceil(
            data.meta.total / data.meta.per_page
          )
          state.pagination.activePage = Number(state.pagination.activePage)
          state.pagination = JSON.parse(JSON.stringify(state.pagination))
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const openFavoritesModal = (event, item) => {
      modal.open(FavoritesForm, {
        size: "md",
        title: "Manage Admin favourite locations and people",
        help: "fav_rooms_help",
        props: {
          roomData: item
        }
      })
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (state.isLazyLoadingMode) {
            const items =
              state.filterParams.activeTab.value == "files"
                ? roomFiles.value
                : rooms.value.data

            if (items.length && state.pagination.total > items.length) {
              if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
                state.pagination.activePage = state.pagination.activePage + 1
                if (state.filterParams.activeTab.value == "files") {
                  getRoomFilesTableData(state.pagination.activePage, true)
                } else {
                  getTableData(state.pagination.activePage, false, true)
                }
              }
            }
          }
        }
      }
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        if (state.filterParams.activeTab.value == "files") {
          state.pagination.per_page = option
          getRoomFilesTableData()
        } else {
          state.pagination.per_page = option
          getTableData()
        }
      }
    }

    const allowAPTRequestToRoom = (room) => {
      store
        .dispatch("rooms/toggleAPTRequest", {
          id: room.id
        })
        .then(() => {
          getTableData()
        })
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      getTableData()
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const generateExport = (params) => {
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store.dispatch("rooms/exportRooms", params).then((response) => {
        if (response.data) {
          download.CSVExport(response.data, "locations(rooms)")
          state.isProcessing = false
        }
      })
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      if (state.filterParams.activeTab.value == "files") {
        getRoomFilesTableData(page)
      } else {
        getTableData(page)
      }
      helpers.scrollToTop()
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        getTableData()
      }
    }

    const addAdminFav = (item) => {
      if (user.value.role.name == "admin") {
        store.dispatch("favorites/toggleFavorite", item).then(() => {
          getTableData()
        })
      }
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        name: {
          value: "",
          column: ["name"],
          sortBy: "name"
        },
        comment: {
          value: "",
          column: ["comment_type"],
          sortBy: "comment_type"
        },
        trip: {
          value: "",
          column: ["trip_type"]
        },
        extended_pass_time: {
          value: "",
          column: ["extended_pass_time"]
        }
      }
    }
    const resetRoomFilesPagination = () => {
      state.pagination = {
        ...state.pagination,
        activePage: 1,
        total: 0,
        pages: 0
      }
    }

    const editRoomIcon = (room) => {
      modal.open(NewRoomForm, {
        size: "sm",
        title: "Assign icon to location",
        props: {
          editLocation: room,
          editIconMode: true
        }
      })
    }

    const handleTableAction = (event, item) => {
      if (event.action == "edit") {
        editRoom(item)
      }
    }

    watch(
      () => modal.options.action,
      (action) => {
        if (action === "createRoom" || action === "updateRoom") {
          state.pagination.activePage =
            state.pagination.per_page?.label != "All entries"
              ? state.pagination.activePage
              : 1
          getTableData(state.pagination.activePage)
          helpers.scrollToTop()
        } else if (action === "createRoomsCsvFile") {
          if (state.filterParams.activeTab.value == "files") {
            state.pagination.activePage =
              state.pagination.per_page?.label != "All entries"
                ? state.pagination.activePage
                : 1
            getRoomFilesTableData(state.pagination.activePage)
          }
        }
      }
    )

    const editRoom = (room) => {
      modal.open(NewRoomForm, {
        size: "sm",
        title: "Edit Location",
        props: {
          editLocation: room
        }
      })
    }

    return {
      state,
      dataTableFields,
      roomAction,
      activeTableFields,
      roomsCollection,
      user,
      fromFavorites,
      getTableData,
      openFavoritesModal,
      setPerPage,
      allowAPTRequestToRoom,
      sortByColumn,
      toggleSearchByColumn,
      generateExport,
      setActivePage,
      searchByQuery,
      addAdminFav,
      editRoomIcon,
      handleTableAction,
      setActiveTableTab,
      roomFiles
    }
  }
}
</script>
