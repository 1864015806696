export const tableConfig = {
  columnSearchKeys: {
    globalQuery: "",
    date: {
      value: "",
      column: ["for_date"]
    },
    period: {
      value: "",
      column: ["period.name"],
      sortBy: "period.name"
    },
    from: {
      value: "",
      column: ["from.name"],
      sortBy: "from.name"
    },
    to: {
      value: "",
      column: ["to.name", "to.first_name", "to.last_name"],
      sortBy: "to.name"
    },
    confirm_cancel: {
      value: "",
      column: ["confirm_cancel"]
    },
    comments: {
      value: "",
      column: ["latest_comment.comment"]
    }
  },
  dataTableFields: [
    {
      key: "for_date",
      label: "Date",
      type: "pass-view",
      _style: "min-width:140px"
    },
    {
      key: "string_status",
      label: "Status",
      _style: "min-width:150px",
      type: "pass-view"
    },
    {
      key: "period",
      label: "Period",
      _style: "min-width:150px",
      type: "pass-view"
    },
    {
      key: "from",
      label: "From",
      type: "pass-view"
    },
    {
      key: "to",
      label: "To",
      type: "pass-view"
    },
    {
      key: "confirm_cancel",
      label: "Confirmed/Canceled",
      type: "pass-view",
      _style: "min-width:180px"
    },
    {
      key: "actions",
      _style: "min-width:150px"
    },
    {
      key: "comments",
      label: "Comments",
      type: "pass-view",
      _style: "min-width:150px"
    }
  ]
}
export default tableConfig
